import React, { useState } from "react";
import { Link } from "react-router-dom";
import AuthService from "../services/auth.service";
import { EyeSlash, EyeOpen, Onboarding1 } from "./common/Icon";
import { useNavigate } from "react-router-dom";


const Login = () => {

  console.log("Dans Login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const required = (value) => {
    if (!value.toString().trim().length) {
      return "Ce champ est requis!";
    }
    return null;
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email) {
      setMessage("* Email est obligatoire");
      return;
    }
    if (!password) {
      setMessage("* Mot de passe est obligatoire");
      return;
    }

    setMessage("");
    setLoading(true);

    const emailError = required(email);
    const passwordError = required(password);

    if (!emailError && !passwordError) {
      try {
        const response =  await AuthService.login(email, password, rememberMe);
        console.log("response ", response);
        if (response){
          navigate("/home", { replace: true });
        }
      }catch (error){
          setLoading(false);
          console.log("error", error)
          setMessage(
            "Erreur survenue. Veuillez réessayer.",error
          );
      }
    } else {
      setLoading(false);
      setMessage(emailError || passwordError);
    }
  };

  const loginDemo = async (e) => {
    console.log("try logindemo ");
    e.preventDefault();
    try {
      console.log("try logindemo ");
      const response =  await AuthService.login("dcaretest@dcareapp.fr", "dcaretest",false);
      console.log("response ", response);
      const loginCount  = AuthService.getLoginCount();
      if (response){
        if (loginCount>2) navigate("/welcome");
        else navigate("/home", { replace: true });
        
      }
    }catch (error){
        setLoading(false);
        console.log("error", error)
        setMessage(
          "Erreur rencontrée : ",error
        );
    }
  };

  const onToggleRememberMe = () => {
    setRememberMe((prevState) => !prevState);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        backgroundColor: "white",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "1200",
      }}
    >
      <div
        className="col-lg-6 mx-auto"
        style={{ padding: "20px", maxWidth: "992px" }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={process.env.PUBLIC_URL + "icons/Logo_dcare.svg"}
            alt="Logo de DCARE"
            style={{ width: "200px" }}
          />
        </div>

        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="mt-3" style={{ maxWidth: "384px" }}>
            <form onSubmit={handleLogin} aria-labelledby="login-form-title">
              <h1
                id="login-form-title"
                className="text-left mb-1"
                style={{ fontSize: "1.25em", fontWeight: "700" }}
              >
                Se connecter
              </h1>
              <p style={{ fontSize: "1em", fontWeight: "400" }}>
                Accédez aux informations de vos appareils et gérez-les de manière
                plus écologique et efficace
              </p>

              {message && (
                <div
                  className="mt-3 mb-3 error-message"
                  role="alert"
                  aria-live="assertive"
                >
                  {message}
                </div>
              )}

              <div>
                <div className="form-group">
                  <label htmlFor="email" className="text-left text-medium">
                    Email
                  </label>

                  <input
                    id="email"
                    placeholder="Entrez votre email"
                    type="text"
                    name="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="username"
                    aria-required="true"
                  />
                </div>

                <div className="mt-4 form-group">
                  <label htmlFor="password" className="text-left text-medium">
                    Mot de passe
                  </label>
                  <div style={{ position: "relative" }}>
                    <input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Entrez votre mot de passe"
                      className="form-control"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"
                      aria-required="true"
                    />
                    <button
                      type="button"
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <EyeOpen /> : <EyeSlash />}
                    </button>
                  </div>
                </div>

                <div className="mb-3" style={{ display: "flex" }}>
                  <input
                    type="checkbox"
                    id="rememberMe"
                    checked={rememberMe}
                    onChange={onToggleRememberMe}
                    style={{ width: "unset" , accentColor: rememberMe ? 'var(--color-green)' : 'initial'}}
                  />
                  <label htmlFor="rememberMe" className="ms-2 mb-0">
                    Se souvenir de moi
                  </label>
                </div>

                <Link
                  className="mt-3 text-medium"
                  to={"/register"}
                  style={{
                    color: "var(--color-green)",
                    textDecoration: "none",
                  }}
                >
                  Mot de passe oublié ?
                </Link>

                <div className="mt-3">
                  <button
                    className="btn-primary btn-block"
                    style={{ width: "100%" }}
                    disabled={loading}
                    aria-label="Valider la connexion"
                  >
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    <span>VALIDER</span>
                  </button>
                </div>
              </div>
            </form>

            <div className="mt-4 text-center">
              <span style={{ fontSize: "0.875rem" }}>
                Pas de compte ? Créez-en un en quelques clics !
              </span>
              <Link
                to={"/register"}
                className="ms-1 text-medium"
                style={{
                  color: "var(--color-green)",
                  textDecoration: "none",
                  paddingRight: "10px",
                }}
              >
                Créer un compte
              </Link>
            </div>

            <button
              className="mt-2 btn-secondary btn-block"
              style={{ width: "100%" }}
              onClick={loginDemo}
              aria-label="Utiliser la démo pour se connecter"
            >
              DEMO
            </button>
          </div>
        </div>
      </div>
      <div
        className="col-lg-6 d-none d-lg-block h-100"
        style={{ overflow: "hidden" }}
      >
        <Onboarding1
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  );
};

export default Login;
