import React, { useEffect, useState } from "react";
import Calendar from 'react-calendar';

import {useNavigate } from "react-router-dom";

import eventService from "../../services/event.service";

import 'react-calendar/dist/Calendar.css';
import "../../css/calendar.css";
import { IconByType }  from "../common/Icon";
import getLabel from "../../services/glossary.service";

import { PageTitle } from "../common/Header";
import ViewEvent from "./viewevent";

const ViewCalendar = () => {

  const [date, setDate] = useState(new Date());

  const [selectedEvents, setSelectedEvents] = useState([]); // Stocker plusieurs événements


  const [events, setEvents] = useState(null);
  const [eventComing, setEventComing] = useState([]);
  const [eventPassed, setEventPassed] = useState([]);

  
  const navigate = useNavigate();
  
  
  const [showAll, setShowAll] = useState(false);

  const [showAllComing, setShowAllComing] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const toggleShowAllComing = () => {
    setShowAllComing(!showAllComing);
  };

  // Affiche les 5 premiers éléments si showAll est false, sinon tous les événements
  const displayedEvents = showAll ? eventPassed : eventPassed.slice(0, 3);

  // Affiche les 5 premiers éléments si showAll est false, sinon tous les événements
  const displayedEventsComing = showAllComing ? eventComing : eventComing.slice(0, 3);


  // Définir la fonction showAllBadges
  const gotoMaintenanceProduct = () => {
    const tomaintenance=true;
    navigate(`/productlist`,{ state: { tomaintenance } });
  };

  useEffect(() => {

    const fetchData = async () => {
      try {
        // Récupérer les événements par appliance
        const eventResponse = await eventService.getEventByUser();
        const allEvents = eventResponse.data;

        // Date actuelle
        const today = new Date();

        // Séparation des événements
        const upcomingEvents = allEvents
        .filter(event => new Date(event.dt_realisation) > today)
        .sort((a, b) => new Date(a.dt_realisation) - new Date(b.dt_realisation)); // Trier du plus ancien au plus éloigné
        ;
        const pastEvents = allEvents.filter(event => new Date(event.dt_realisation) < today);

        setEvents(allEvents);
        setEventComing(upcomingEvents);
        setEventPassed(pastEvents);
      
        console.log('Events récupérés:', allEvents);
        console.log('Events à venir:', upcomingEvents);
        console.log('Events passés:', pastEvents);
      } catch (error) {
        console.error('Une erreur s\'est produite:', error);
      }
    };

    fetchData(); // Appel de la fonction asynchrone

  }, []);


  const tileClassName = ({ date, view }) => {
    if (events && view === 'month') {
      // Formatage des dates pour la comparaison
      const eventDates = events.map((event) => {
        const eventDate = new Date(event.dt_realisation);
        eventDate.setHours(0, 0, 0, 0); // Normalisation de l'heure à zéro
        return eventDate.getTime(); // Retourne le timestamp normalisé pour la comparaison
      });
  
      // Normalisation de la date actuelle pour la comparaison
      const normalizedDate = new Date(date);
      normalizedDate.setHours(0, 0, 0, 0); // Normalisation de l'heure à zéro
  
      // Si l'événement existe à cette date, retourne une classe
      return eventDates.includes(normalizedDate.getTime()) ? 'has-event' : 'no-event';
    }
  
    return null;
  };
  

  const handleClickDay = (clickedDate) => {
      // Normalisation de la date cliquée
      const normalizedClickedDate = new Date(clickedDate);
      normalizedClickedDate.setHours(0, 0, 0, 0); // Mettre les heures, minutes, secondes et millisecondes à zéro

      // Recherche des événements correspondant à la date cliquée
      const eventsOnThisDay = events.filter((event) => {
        const eventDate = new Date(event.dt_realisation);
        eventDate.setHours(0, 0, 0, 0); // Normalisation de l'heure à zéro
        return eventDate.getTime() === normalizedClickedDate.getTime(); // Comparaison des dates normalisées
      });

      console.log("Événements du jour trouvé :", eventsOnThisDay);
      setSelectedEvents(eventsOnThisDay); // Stocker les événements correspondants

  };

  return (
    
    <div className="pt-4">

      <PageTitle title={"Mon calendrier"}/>

      <div className="calendar-container mb-4 mt-4" > 
        <Calendar
          onChange={setDate}
          value={date}
          tileClassName={tileClassName}
          onClickDay={handleClickDay} // Ajoute cette méthode pour gérer le clic sur la date
        />
      </div>

        {events && events.length > 0 && (
         <div className="col-12 mt-3" > 


          {selectedEvents && selectedEvents.length > 0 && (
            <div className="mt-3 mb-3">
            <span style={{ fontSize: '1rem', fontWeight: '500' }}>Mes évènements du {new Date(date).toLocaleDateString()}</span>
              <div className="mt-2"  style={{ border: '1px solid var(--color-green)'}}>
              {selectedEvents.map((event, index) => (
                <ViewEvent key={index} event = {event}/>
              ))}
              </div>
          </div>
          )}

          {displayedEventsComing && displayedEventsComing.length > 0 && (
             <div>
              <span style={{ fontSize: '1rem', fontWeight: '500' }}>Mes évènements à venir</span>
                  {displayedEventsComing.map((event, index) => (
                    <ViewEvent key={index} event = {event}/>
                    ))}
                      {/* Bouton pour afficher/masquer les autres événements */}
                      {eventComing.length > 3 && (
                      <div 
                        style={{ cursor: 'pointer', textAlign: 'right', fontSize: '0.875rem', fontWeight: '400', marginTop: '0.5rem' }} 
                        onClick={toggleShowAllComing}
                      >
                        {showAllComing ? 'Voir moins' : 'Voir plus'}
                      </div>
                    )}
                  </div>
              )}

          {displayedEvents && displayedEvents.length > 0 && (
                  <div>
                    <div className="mt-3" style={{ fontSize: '1rem', fontWeight: '500' }}>Mes réalisations</div>
                          {displayedEvents.map((event) => (
                            <div key={event.id} className="read-notification"
                            style={{ 
                              padding: "0.5rem",
                              borderBottom: "1px solid lightgray", // Add bottom border"
                              position: 'relative', display: 'flex'
                            }} >
                              <div className="icon-column">
                                  <div >
                                  {event.type === 'MAINTENANCE' && 
                                              <div className="card-caroussel light-green-background">
                                                  <img 
                                                    src={process.env.PUBLIC_URL + '/icons/Maintenance.svg'} 
                                                    style={{ width: 'auto', height: '2rem', verticalAlign: 'middle' }} alt="maintenance" />
                                              </div>
                                            }
                                  </div>
                              </div>
                              <div className="ms-3 d-flex flex-column justify-content-center flex-grow-1">
                                  <div className="d-flex justify-content-between"> 
                                    <span style={{ fontSize: '0.75rem', fontWeight: '600', textTransform: 'uppercase'}}>{getLabel(event.type)}</span>
                                    <span style={{ fontSize: '0.65rem', fontWeight: '400' }}>{new Date(event.dt_realisation).toLocaleDateString()}</span>
                                  </div>
                                  
                                  <div className="mt-2" style={{ fontSize: '0.75rem', fontWeight: '400' }}>
                                    {event.title.charAt(0).toUpperCase() + event.title.slice(1).toLowerCase()}
                                    </div> 
                              </div>
                              
                            </div>
                            ))}
                              {/* Bouton pour afficher/masquer les autres événements */}
                              {eventPassed.length > 3 && (
                                <div 
                                  style={{ cursor: 'pointer', textAlign: 'right', fontSize: '0.875rem', fontWeight: '400', marginTop: '0.5rem' }} 
                                  onClick={toggleShowAll}
                                >
                                  {showAll ? 'Voir moins' : 'Voir plus'}
                                </div>
                              )}
                      </div>
                      
                )}
                <button className="mt-4 mb-4 btn btn-primary btn-block"
                onClick={() => gotoMaintenanceProduct()}>
                  <span>Planifier un entretien</span>
                </button>
        </div>
      )}
    </div>
  );
};

export default ViewCalendar;