import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Banner = () => {

    // Liste des messages
    const messages = [
        "Un entretien régulier, c'est la garantie d'une performance optimale.",
        "Prendre soin de tes appareils aujourd'hui, c'est prolonger leur durée de vie demain.",
        "Un appareil bien entretenu, c'est moins de pannes et plus de tranquillité.",
        "L'entretien, c'est préserver ton quotidien. Ne le remets pas à plus tard !",
        "Un petit geste pour tes appareils, un grand pas pour leur longévité.",
        "Planifie l'entretien de tes appareils : une économie d'énergie et de budget.",
        "Un entretien simple aujourd'hui pour éviter les réparations coûteuses demain."
      ];

    // État pour stocker le message sélectionné
    const [message, setMessage] = useState("");

    // Fonction pour choisir un message aléatoire
    const getRandomMessage = () => {
        const randomIndex = Math.floor(Math.random() * messages.length);
        return messages[randomIndex];
    };

    // Définir un message lors du premier rendu
    useEffect(() => {
        setMessage(getRandomMessage());
    }, []); // Vide pour ne s'exécuter qu'une seule fois au montage du composant

return (
    <div className='banner'>
    <div            
    style={{ 
        margin: "0px",
        padding: "0px",
        height:"163px",
        width: "100%",
        borderRadius: "0px", 
        position: "relative",
        overflow: "hidden",
        boxSizing: "border-box",
        backgroundColor:"#ffffff",
    }}>
    {/* BannerA (gauche) */}
        <img className='bannerA'
            src={process.env.PUBLIC_URL + '/icons/BannerA.svg'}
            alt="Banner Left"
           
        />
        
        {/* BannerB (droite) */}
        <img className='bannerB'
            src={process.env.PUBLIC_URL + '/icons/BannerB.svg'}
            alt="Banner Right"

        />
    
         
            <div className="text-banner text-small">
                    <span>{message}</span>
            </div>
            <div 
                className="text-banner mt-1">
                 <a
                        style={{
                            background:"var(--color-green)",
                            borderRadius:"0.25rem",
                            padding: "5px",
                            color: "white",              
                            fontSize:"12px",
                            fontWeight:"500",
                            textDecoration: "none",
                            }} href="/#/calendar">EN SAVOIR PLUS &rarr; {/* Flèche droite simple */}</a>
            </div>               
        
    </div >
    </div >
    );
};

export default Banner;