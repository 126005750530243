import React, { useState, useEffect} from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import ApplianceService from "../../services/appliance.service";

import AuthService from "../../services/auth.service";
import EventBus from "../../common/EventBus";


import UniverCRUDForm from "../common/UniverCRUDForm";

import ApplianceListView from "../common/ApplianceListView";
import {PageTitle} from '../common/Header'
import { EditIcon } from "../common/Icon";

const UniverDetail = () => {
  // Récupérer l'objet univer depuis l'état de location
  const location = useLocation();
  const { univer } = location.state || {}; // L'objet univer est dans location.state
  const [productList, setProductList] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const navigate = useNavigate(); 


  const fetchApplianceData = async () => {
    try {

       // Appel de l'API avec async/await
      const response = await ApplianceService.getApplianceByUserAndUniver(univer.univer_id);
      
      // Vérifie si la réponse est bien un tableau, sinon assigne un tableau vide
      const productData = Array.isArray(response.data) ? response.data : [];
      setProductList(productData);
  
      console.log('Dans inventaire des produits', response.data);
    } catch (error) {
      console.error('Une erreur s\'est produite:', error);
  
      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    }
  };


  useEffect(() => {
    
    fetchApplianceData();


  }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial



  // Fonction appelée lorsqu'un univers est cliqué
  const handleEditClick = () => {
    console.log("edit univer ");
    setShowUpdateModal(true);
    
};

const handleClose = (workedUniver) => {
  setShowUpdateModal(false);
  console.log("Univers retravaillé ", workedUniver);
  // Rediriger avec l'objet univer mis à jour dans l'état
  navigate("/inventaire/univers");

};

const gotoAddDevice = () => {
  navigate("/new-device",{ state: { univer: univer } });
};

  // Fonction appelée lorsqu'un univers est cliqué
  const gotoProduct = (currentProduct) => {
    console.log("appareil sent ",currentProduct);

    navigate(`/inventaire/univers/appliance/fichedetail`, { state: { appliance: currentProduct } });
  };

  
  return (
            <div>
                  <PageTitle title = {univer.name}/>
            
                  {/* Affichage de la liste des univers */}
                  <div className="mt-4">
                    <div className="boxShadow mb-3 green-border p-2">
                      <img 
                        src={univer.image} 
                        alt={univer.name || 'Image descriptive manquante'} 
                        className="img-fluid mb-2 d-block mx-auto"
                        style={{ maxHeight: '200px', objectFit: 'cover' }}
                        role="img" 
                      />
                      <div className="mt-4 mb-2" style={{ position: 'relative' }}>
                          <div
                              className="d-flex justify-content-between align-items-end text-medium"
                              style={{ paddingRight: '2rem', height: '100%' }}
                              role="group" // Utilisation d'un rôle sémantique pour regrouper les éléments
                              aria-label="Description et options d'édition"
                          >
                              <div style={{ alignSelf: 'flex-end' }}>
                                  <span id="description">Description</span>
                              </div>
                              <EditIcon onClick={() => handleEditClick()} />
                          </div>
                      </div>
                      <div 
                        id="univerDescription" 
                        className="text-small"
                        aria-label={`Description of ${univer.description || 'this item'}`}
                      >
                        {univer.description}
                      </div>
                      
                    </div>

                    {productList.length === 0 && (
                    <div className="text-center text-medium">Ma pièce est vide</div>
                    )}

                    <button className="mt-3  btn btn-primary btn-block" onClick={gotoAddDevice}>
                        <span>AJOUTER UN APPAREIL</span>
                    </button>

                    {/* Liste des produits */}
                     {productList.length>0 && (<ApplianceListView 
                        listToDisplay={productList} 
                        handleClicktoDetail={gotoProduct} 
                        aria-label="Liste des produits disponibles"
                      />)}
                  </div>

                  
                        {/* Render Logout Confirmation Modal */}
                {showUpdateModal && <UniverCRUDForm show={showUpdateModal} onClose={handleClose} univer={univer} action='UPDATE' isModal={true}/>}
                            

               

              </div>
  
        
  );
};

export default UniverDetail;
