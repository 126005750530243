import React, { useState } from "react";
import { isEmail } from "validator";
import AuthService from "../services/auth.service";
import {EyeSlash, EyeOpen,Onboarding2} from "./common/Icon";
import { Link } from 'react-router-dom';

const required = (value, fieldName) => {
  if (!value) {
    return `Le champ "${fieldName}" est requis!`;
  }
  return null;
};

const validateEmail = (value) => {
  if (!value) {
    return "Veuillez entrer votre adresse e-mail.";
  }
  if (!isEmail(value)) {
    return "Veuillez entrer une adresse e-mail valide.";
  }
  return null;
};

const validateName = (value, fieldName) => {
  if (!value) {
    return `Veuillez entrer votre ${fieldName}.`;
  }
  if (value.length < 3 || value.length > 20) {
    return `Votre ${fieldName} doit contenir entre 3 et 20 caractères.`;
  }
  return null;
};

const validatePassword = (value) => {
  const passwordRules = [
    {
      rule: /.{8,}/,
      message: "au moins 8 caractères.",
    },
    {
      rule: /(?=.*[a-z])(?=.*[A-Z])/,
      message: "au moins une lettre majuscule et une lettre minuscule.",
    },
    {
      rule: /(?=.*\d)/,
      message: "au moins un chiffre.",
    },
    {
      rule: /(?=.*[@$!%*?&])/,
      message: "au moins un caractère spécial (@$!%*?&).",
    },
  ];

  const errors = passwordRules
    .filter((rule) => !rule.rule.test(value))
    .map((rule) => rule.message);

  if (errors.length > 0) {
    return `Votre mot de passe doit contenir :<br />- ${errors.join("<br />- ")}`;
  }
  return null;
};

const Register = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // État pour afficher/masquer le mot de passe
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // État pour afficher/masquer la confirmation

  const handleRegister = (e) => {
    e.preventDefault();
    setMessage("");
    setSuccessful(false);
  
    const firstnameError = validateName(firstname, "prénom");
    const lastnameError = validateName(lastname, "nom");
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);
  
    if (password !== confirmPassword) {
      setMessage("Les mots de passe ne correspondent pas.");
      return;
    }
  
    if (firstnameError || lastnameError || emailError || passwordError) {
      setMessage(firstnameError || lastnameError || emailError || passwordError);
      return;
    }
  
    AuthService.register(email, firstname, lastname, email, password).then(
      (response) => {
        setMessage(response.data.message);
        setSuccessful(true);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        setMessage(resMessage);
        setSuccessful(false);
      }
    );
  };

  return (
    <div style={{ position: 'absolute', top: '0', left: '0', backgroundColor: 'white', height: '100vh', width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div className="col-12 col-lg-6 mx-auto" style={{ padding: '20px', height:'100vh',maxWidth: "992px" }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={process.env.PUBLIC_URL + 'icons/Logo_dcare.svg'}
            alt="Logo de DCARE"
            style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: "180px" }}
          />
        </div>
        <br />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <div className="mt-3" style={{ maxWidth: '384px' }}>
            <div role="form" aria-labelledby="register-form-title">
              <h1 id="login-form-title" className="text-left mb-1" style={{ fontSize: '1.25em', fontWeight: '700' }}>Créer un compte</h1>
              <p style={{ fontSize: '1em', fontWeight: '400' }}>Accédez aux informations de vos appareils et gérez-les de manière plus écologique et efficace</p>

              {message && (
                <div className="form-group">
                  <div
                    className={successful ? "alert alert-success" : "error-message"}
                    role="alert"
                    aria-live="assertive"
                    dangerouslySetInnerHTML={{ __html: message }} // Utilisation de dangerouslySetInnerHTML
                  />
                </div>
              )}

              {!successful && (
                <div className='mt-4'>
                  <div className="form-group">
                    <label htmlFor="firstname">Prénom</label>
                    <input
                      type="text"
                      id="firstname"
                      className="form-control"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                      placeholder="Entrez votre prénom"
                      aria-required="true"
                      aria-describedby="firstname-error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="lastname">Nom</label>
                    <input
                      type="text"
                      id="lastname"
                      className="form-control"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      placeholder="Entrez votre nom"
                      aria-required="true"
                      aria-describedby="lastname-error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      id="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Entrez votre email"
                      aria-required="true"
                      aria-describedby="email-error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Mot de passe</label>
                    <div style={{ position: "relative" }}> {/* Conteneur relatif */}
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Entrez votre mot de passe"
                        aria-required="true"
                        aria-describedby="password-error"
                        style={{ paddingRight: "40px" }} /* Ajouter un padding à droite pour éviter que le texte ne chevauche l'icône */
                      />
                      <button
                        type="button"
                        style={{
                          position: "absolute", /* Position absolue par rapport au conteneur */
                          right: "10px", /* Aligner à droite */
                          top: "50%", /* Centrer verticalement */
                          transform: "translateY(-50%)", /* Ajuster le centrage */
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          padding: "0", /* Supprimer le padding par défaut */
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <EyeOpen /> 
                        ) : (
                          <EyeSlash />
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirmez le mot de passe</label>
                    <div style={{ position: "relative" }}> {/* Conteneur relatif */}
                      
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirmPassword"
                        className="form-control"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirmez votre mot de passe"
                        aria-required="true"
                        aria-describedby="confirmPassword-error"
                        style={{ paddingRight: "40px" }} /* Ajouter un padding à droite pour éviter que le texte ne chevauche l'icône */
                      />
                      <button
                        type="button"
                        style={{
                          position: "absolute", /* Position absolue par rapport au conteneur */
                          right: "10px", /* Aligner à droite */
                          top: "50%", /* Centrer verticalement */
                          transform: "translateY(-50%)", /* Ajuster le centrage */
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          padding: "0", /* Supprimer le padding par défaut */
                        }}
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      >
                        {showConfirmPassword ? (
                          <EyeOpen/>
                        ) : (
                          <EyeSlash/>
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="mt-4 form-group">
                    <button
                      className="btn-primary btn-block"
                      onClick={handleRegister}
                      aria-label="S'inscrire"
                      style={{width: '100%'}}
                    >
                      S'inscrire
                    </button>
                    <div className="mt-2 text-center">
                        <span style={{ fontSize: '0.875rem' }}>Vous avez un compte ? </span>
                        <Link to={"/login"} className='text-medium' style={{ color: 'var(--color-green)', textDecoration: 'none', paddingRight: '10px' }}>Se connecter</Link>
                      </div>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 d-none d-lg-block h-100" style={{ overflow: 'hidden' }}>
              <Onboarding2 
              style={{
                height: '100%',      // Remplit la hauteur du conteneur
                width: '100%',       // Remplit la largeur du conteneur
                objectFit: 'cover'   // Agrandit et coupe pour s'adapter parfaitement
              }} ></Onboarding2>
        </div>
    </div>
  );
};

export default Register;