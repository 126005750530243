import api from './api';
import authHeader from './auth-header';

import config from './config';
import AuthService from './auth.service';

//const API_URL = `${config.serverIP}/api/test`;
const API_URL = `${config.serverIP}/${config.apiVersion}`;


class UserService {

  getBadge(){
    const userId = AuthService.getUserId();

    console.log('call userservice  :');
    const url = `${API_URL}/profils/users/${userId}/badges`;
    console.log('url  :', url);
    return api.get(url,{ headers: authHeader() });
  }


  getPublicContent() {
    return api.get(API_URL + 'all');
  }

  getUserBoard() {
    return api.get(API_URL + 'user', { headers: authHeader() });
  }

  getFreemiumBoard() {
    return api.get(API_URL + 'freemium', { headers: authHeader() });
  }

  getAssureBoard() {
    return api.get(API_URL + 'assure', { headers: authHeader() });
  }
}

// Créez une instance 
const UserServiceInstance = new UserService();

// Exportez l'instance en tant qu'export par défaut
export default UserServiceInstance;

//export default new UserService();