import api from './api';
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';
import authHeader from './auth-header';

const API_URL = `${config.serverIP}/api/ai`;

class SearchService {
   async searchAndSaveByImage(urlImage,searchText,userId,univerId,refProdId) {
        try {
            const request={
                "urlImage" : urlImage,
                "searchText" : searchText,
                "userId" : userId,
                "univerId":univerId,
                "refProdId":refProdId
            }
            const url = `${API_URL}/appliance/searchandsave`
            console.log('request  :', request);
            const response = await api.post(url,request,{ headers: authHeader() });
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    async searchImage(urlImage) {
        try {
            const request={
                "urlImage" : urlImage,
            }
            console.log('request  :', request);
            const url = `${API_URL}/images`
            const response = await api.post(url,request,{ headers: authHeader() });
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.error(error);
            return null; // Retourne null en cas d'erreur pour un meilleur contrôle côté appelant
        }
    }
}


// Créez une instance 
const SearchServiceInstance = new SearchService();

// Exportez l'instance en tant qu'export par défaut
export default SearchServiceInstance;

//export default new SearchService();