import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import "../../css/entretienproduct.css";
import { useNavigate, useLocation } from 'react-router-dom';
import ImageUploader  from "../common/ImageUploader";
import {Modal} from "react-bootstrap"; 
import ImagePopup from "../common/ImagePopup"; 
import eventService from "../../services/event.service";
import { UpdateImagePopUp } from "../common/MiniFunction";
import AuthServiceInstance from "../../services/auth.service";

import ApplianceService from "../../services/appliance.service";
import config from '../../services/config';

import { EditIcon}  from "../common/Icon";
import ApplianceServiceInstance from "../../services/appliance.service";
import { PageTitle } from "../common/Header";

const ApplianceDetail = () => {


    const navigate = useNavigate(); 
    const location = useLocation();
    const { appliance } = location.state || {}; // L'objet appliance est dans location.state

    //etat pour les popups
    const [factureIsOpen, setFactureIsOpen] = useState(false);
    const [noticeIsOpen, setNoticeIsOpen] = useState(false);
    const [imageIsOpen, setImageIsOpen] = useState(false);
    
    
    
    const [apDetail, setApDetail] = useState([]);
    const [events, setEvents] = useState([]);
    const [eventsMaintenance, setEventsMaintenance] = useState([]);
    const [eventsRepair, setEventsRepair] = useState([]);


    const toggleFacture = () => {
        console.log("Clicked! facture");
        setFactureIsOpen(!factureIsOpen);
    };

    const toggleImage = () => {
        setImageIsOpen(!imageIsOpen);
    };

    const handleImageUpload = async (imagefile) => {
        try {
            const userId=AuthServiceInstance.getCurrentUser().id;
            const applianceId = apDetail.id;
            const applianceUpdated = await ApplianceServiceInstance.updateAppliancePict(userId, applianceId,imagefile)
            setApDetail(applianceUpdated);
        } catch (error) {
            console.error('Une erreur s\'est produite:', error);
        } 
    };

    const handleFactureUpload = async (imagefile) => {
        try {
            const userId=AuthServiceInstance.getCurrentUser().id;
            const applianceId = apDetail.id;
            const applianceUpdated = await ApplianceServiceInstance.updateApplianceInvoice(userId, applianceId,imagefile)
            setApDetail(applianceUpdated);
        } catch (error) {
            console.error('Une erreur s\'est produite:', error);
        } 
    };

    const gotoMaintenance = () => {
        console.log("Clicked! maintenance", appliance);
        navigate(`/entretien/fiche`, { state: { product: appliance } });
    };

    const gotoSpareka = () => {
        console.log("Clicked! maintenance",appliance);
        window.open(config.SPAREKA_URL, '_blank');
    };

    const gotoRepair = (repairEvent) => {
        console.log("Clicked! repair");
        if(repairEvent) {
            navigate(`/repair`, {
                state: {
                    product: apDetail,
                    event: repairEvent, // Ajoutez repairEvent dans l'état
                },
            });
        }
        else navigate(`/repair`, { state: { product: appliance } });
    };

    const gotoSecondLife = () => {
        console.log("Clicked! secondlife");
        navigate(`/secondlife/actionselect`, { state: { product: appliance } });
    };

    const toggleNotice = () => {
        console.log("Clicked! Notice");
        setNoticeIsOpen(!noticeIsOpen);
    };

    useEffect(() => {
        if (!appliance || !appliance.id) {
            console.error("L'objet appliance n'est pas défini ou ne contient pas d'id");
            navigate("/home"); // Rediriger si appliance n'existe pas
            return; // On quitte l'effet si appliance n'est pas défini
        }
        const fetchData = async () => {
            try {
                // Récupérer les détails de l'appareil
                const applianceResponse = await ApplianceService.getApplianceDetail(appliance.id);
                setApDetail(applianceResponse.data);
                console.log('Détail du produit ', applianceResponse.data);
    
                // Récupérer les événements par appliance
                const eventResponse = await eventService.getEventByAppliance(appliance.id);
                setEvents(eventResponse.data);
                console.log('Events récupérés response data', eventResponse.data);
                let maintenanceEvents=false;
                let repairEvents=false;

                if(eventResponse.data){
                    // Filtrer les événements de type 'MAINTENANCE' et 'REPAIR'
                    maintenanceEvents = eventResponse.data.filter(event => event.type === "MAINTENANCE");
                    repairEvents = eventResponse.data.filter(event => event.type === "PANNE");
                }
                // Mettre à jour les états pour les événements
                setEventsMaintenance(maintenanceEvents);
                setEventsRepair(repairEvents);
                
                console.log('maintenanceEvents', maintenanceEvents);
                console.log('repairEvents', repairEvents);
    
            } catch (error) {
                console.error('Une erreur s\'est produite:', error);
            }
        };
    
        fetchData(); // Appel de la fonction asynchrone
    
    }, []); // Ajouter appliance.id dans les dépendances si nécessaire
    
    
    // Fonction appelée lorsqu'un univers est cliqué
    const handleEditClick = (currentProduct) => {
        console.log("appareil sent ",currentProduct);
        navigate(`/inventaire/univers/appliance/update`, { state: { appliance: currentProduct } });
    };

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        variableWidth: true,
      };

    return (
        <div>
                   <PageTitle title = {"Fiche Produit"}/>     
                
            <div className="boxShadow">
                    <div className="mt-4 text-center ">   
                            <img 
                                onClick={toggleImage}
                                src={apDetail.pict} 
                                alt={appliance.prod_name} 
                                className="img-fluid"
                                style={{ maxWidth: '18.75rem', maxHeight: '15rem' }}/>
                            {imageIsOpen && <UpdateImagePopUp 
                                onClose={toggleImage}
                                initialImageUrl={apDetail.pict}
                                onImageUpload={handleImageUpload}/>}

                            <div className="mt-1 tex-medium">{appliance.prod_name}</div>
                            
                            <div className="mt-1 text-small">{apDetail.name}</div>
                            {apDetail.classenergie && ( // Vérifie si apDetail.classenergie existe
                                <div className="mt-1 text-small">Classe énergétique : {apDetail.classenergie}</div>
                            )}
                    
                    </div>
                    
                    <div className="mt-4 boxShadow" style={{ position: 'relative' }}>
                        <div
                            className="d-flex justify-content-between align-items-end text-medium"
                            style={{ paddingRight: '2rem', height: '100%' }}
                            >
                            <div style={{ alignSelf: 'flex-end' }}>
                                Description
                            </div>
                            <EditIcon onClick={() => handleEditClick(apDetail)} />
                        </div>
                    </div>

                    <div className="mt-2">   
                            <div className="green-border"
                                style={{ fontSize: '0.75rem', fontWeight: '400', padding: '0.5rem' }}
                            >
                                <div className="">Enregistré le {new Date(apDetail.dt_creation).toLocaleDateString()}</div>
                                <div className="">Date d'achat :  {apDetail.dt_achat ? new Date(apDetail.dt_achat).toLocaleDateString() : 'Inconnu'}</div>
                                <div align="justify">{apDetail.note}</div>  
                            </div>  
                    </div> 

                   {/* Slider services */}
                    <Slider {...settings} className="mt-4 mb-4">
                        <div className="me-2 d-flex align-items-start "
                            onClick={() => toggleNotice()}>
                            <div
                                className="light-green-background d-flex justify-content-center align-items-center"
                                style={{width :120,
                                    borderRadius: '2rem',
                                    fontSize: '0.75rem',
                                    fontWeight: '500',
                                    padding: '0.5rem',
                                    cursor: 'pointer',
                                }}
                                align="center">
                                Consulter la notice
                            </div>
                        </div>
                        <div className="d-flex align-items-start link-text"
                        onClick={() => gotoRepair()}>
                            <div
                            className="light-green-background me-2 d-flex justify-content-center align-items-center"
                            style={{width :120,
                                borderRadius: '2rem',
                                fontSize: '0.75rem',
                                fontWeight: '500',
                                padding: '0.5rem',
                            }}
                            align="center"
                            >
                            Réparer une panne
                            </div>
                        </div>
                        <div className="d-flex align-items-start link-text"
                        onClick={() => gotoSpareka()}>
                            <div
                            className="light-green-background me-2 d-flex justify-content-center align-items-center"
                            style={{width :190,
                                borderRadius: '2rem',
                                fontSize: '0.75rem',
                                fontWeight: '500',
                                padding: '0.5rem',
                            }}
                            align="center"
                            >
                            Besoin d'un accessoire/consommable
                            </div>
                        </div>
                       
                        <div className="me-2 d-flex align-items-start "
                            onClick={() => gotoSecondLife()}>
                            <div
                            className="light-green-background me-2 d-flex justify-content-center align-items-center"
                            style={{width :120,
                                borderRadius: '2rem',
                                fontSize: '0.75rem',
                                fontWeight: '500',
                                padding: '0.5rem',
                            }}
                            align="center"
                            >
                            Donner une seconde vie
                            </div>
                        </div>
                     </Slider>
                    
                    {/* Entretien */}
                     <div>
                        <div 
                            className="text-medium">
                                Entretiens
                        </div>
                        <div className="mt-2 green-border text-small"
                                style={{padding: '0.5rem' }}>
                           <div className="d-flex align-items-center">
                                {/* Colonne gauche - Image avatar */}
                                <div className="ms-1 me-1 text-center">
                                    <img
                                    className="" 
                                    src={
                                        eventsMaintenance.length > 0
                                        ? process.env.PUBLIC_URL + '/icons/Trophy.svg'
                                        : process.env.PUBLIC_URL + '/icons/Confuse.svg'
                                    }
                                    alt="Avatar"
                                    style={{ width: '4.5rem', height: 'auto' }}
                                    />
                                </div>

                                {/* Colonne droite - Contenu */}
                                <div className="p-2">
                                    {eventsMaintenance.length > 0 ? (
                                        <div>
                                            {eventsMaintenance.map((maintenanceEvent) => (  
                                                <div key={maintenanceEvent.id} className="">
                                                    <div className="text-medium">
                                                        {new Date(maintenanceEvent.dt_realisation).toLocaleDateString()}
                                                    </div>
                                                    <div className="text-small">
                                                        {maintenanceEvent.title.toLowerCase()}
                                                    </div>
                                                </div>
                                            ))} 
                                        </div>
                                    ) : (
                                        <div>Pas d'entretien connu pour le moment.</div>
                                    )}
                                     <button className="btn btn-primary btn-block mt-1"
                                        style={{ fontSize: '0.75rem', fontWeight: '500',width:'auto',height:'auto'}}
                                        onClick={() => gotoMaintenance()}>
                                            Voir les tutoriels
                                    </button>
                                </div>
                            </div>
                        </div>  
                    </div> 

                    
                    {/* Reparation */}
                    <div className="mt-4">
                        <div 
                            style={{ fontSize: '0.875rem', fontWeight: '500',}}>
                                Réparations
                        </div>
                        <div className="mt-2 green-border"
                                style={{ fontSize: '0.75rem', fontWeight: '400', padding: '0.5rem' }}>
                           <div className="d-flex align-items-center">
                                {/* Colonne gauche - Image avatar */}
                                <div className="ms-1 me-1 text-center">
                                    <img
                                    className="" 
                                    src={
                                        eventsRepair.length > 0
                                        ? process.env.PUBLIC_URL + '/icons/Cake.svg'
                                        : process.env.PUBLIC_URL + '/icons/Confuse.svg'
                                    }
                                    alt="Avatar"
                                    style={{ width: '4.5rem', height: 'auto' }}
                                    />
                                </div>

                                {/* Colonne droite - Contenu */}
                                <div className="p-2" style={{ width: "100%" }}>
                                    {eventsRepair.length > 0 ? (
                                     <div>
                                        {eventsRepair.map((repairEvent) => (  
                                            <div key={repairEvent.id} className="boxShadow mb-3" onClick={() => gotoRepair(repairEvent)}>
                                                <div className="text-medium">
                                                    {new Date(repairEvent.dt_creation).toLocaleDateString()}
                                                </div>
                                                <div className="text-small">
                                                    {repairEvent.note.toLowerCase()}
                                                </div>
                                            </div>
                                        ))} 
                                     </div>
                                    ) : (
                                    <div>Aucune réparation n'a été signalée.
                                        <button className="btn btn-primary btn-block mt-1"
                                            style={{ fontSize: '0.75rem', fontWeight: '500',width:'auto',height:'auto'}}
                                            onClick={() => gotoRepair(null)}>
                                                Réparer une panne
                                        </button>
                                    </div>
                                    )}
                                     
                                </div>
                            </div>
                        </div>  
                    </div> 

                    {/* Facture */}
                    <div className="mt-4 mb-4">
                        <div 
                            style={{ fontSize: '0.875rem', fontWeight: '500',}}>
                                Facture
                        </div>
                        <div className="mt-2 green-border"
                                style={{ fontSize: '0.75rem', fontWeight: '400', padding: '0.5rem' }}>
                           <div className="d-flex align-items-center">
                                {/* Colonne gauche - Image avatar */}
                                <div className="ms-1 me-1 text-center" style={{ width: '4.5rem', height: '4.5rem' }}>
                                    <img
                                    className="" 
                                    src={
                                        apDetail.facture
                                        ? apDetail.facture
                                        : process.env.PUBLIC_URL + '/icons/Confuse.svg'
                                    }
                                    alt="Avatar"
                                    style={{ maxWidth: '4.5rem', maxHeight: '4.5rem' }}
                                    />
                                </div>

                                {/* Colonne droite - Contenu */}
                                <div className="p-2">
                                    {apDetail.facture ? (
                                     <div></div>
                                    ) : (
                                        <div>Aucune facture trouvée, pensez à en ajouter dès maintenant !</div>
                                    )}
                                    <button className="btn btn-primary btn-block mt-1"
                                        style={{ fontSize: '0.75rem', fontWeight: '500',width:'auto',height:'auto'}}
                                        onClick={() => toggleFacture()}>
                                            Télécharger ma facture
                                    </button>
                                </div>
                            </div>
                        </div>  
                    </div> 
                
                    {factureIsOpen && <UpdateImagePopUp 
                                onClose={toggleFacture}
                                initialImageUrl={apDetail.facture}
                                onImageUpload={handleFactureUpload}/>}

                        
                    
                        <Modal show={noticeIsOpen} onHide={toggleNotice} centered>
                            <Modal.Header closeButton>
                            <Modal.Title><h1 className="mb-2 mt-2">Notice d'utilisation</h1></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <div>
                                {apDetail && !apDetail.notice ? (
                                    <>
                                        <p className="mb-2">Pas de notice trouvée</p>
                                        <ImageUploader uploadImageFunction={ApplianceService.uploadFacture} />
                                    </>
                                    ) : (
                                    <div style={{ height: '500px', width: '100%' }}>
                                        <iframe title="PDF Viewer" src={apDetail.notice} style={{ height: '100%', width: '100%' }}></iframe>
                                    </div>
                                )}
                            </div>
                            </Modal.Body>
                            <Modal.Footer>
                            </Modal.Footer>
                        </Modal>
                        </div>
                </div>

  );
};

export default ApplianceDetail;