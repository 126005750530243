import axios from 'axios';
import config from './config';

// Créez une instance Axios
const api = axios.create({
    baseURL: `${config.serverIP}`, // Remplacez par l'URL de votre backend
});

// Intercepteur pour ajouter le token d'accès à chaque requête
api.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Intercepteur pour gérer les erreurs 401 (token expiré)
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // Si l'erreur est une 401 et que la requête n'a pas déjà été retentée
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            // Récupérer le refresh token
            const refreshToken = localStorage.getItem('refreshToken');

            // Appeler l'endpoint /refreshtoken pour obtenir un nouveau token d'accès
            try {
                const refreshResponse = await axios.post('/api/auth/refreshtoken', {
                    refreshToken,
                });

                const newAccessToken = refreshResponse.data.token;

                // Mettre à jour le token d'accès dans le localStorage
                localStorage.setItem('accessToken', newAccessToken);

                // Répéter la requête originale avec le nouveau token d'accès
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                return api(originalRequest);
            } catch (refreshError) {
                // Si le refresh token est invalide ou expiré, déconnecter l'utilisateur
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                console.log("get current user refresh get out ", refreshError)
                //window.location.href = '/login';
                return Promise.reject(refreshError);
            }
        }
        console.log("get current user refresh get out 2")
        // Si l'erreur n'est pas une 401, la rejeter
        return Promise.reject(error);
    }
);

export default api;