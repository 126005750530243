export default function authHeader() {
  // Vérifier si l'option "rememberMe" est activée
  const rememberMe = localStorage.getItem('rememberMe') === 'true';

  // Récupérer l'utilisateur depuis le localStorage ou le sessionStorage
  const accessToken = rememberMe
      ? localStorage.getItem('accessToken') // Si "rememberMe" est activé
      : sessionStorage.getItem('accessToken'); // Sinon

  // Si l'utilisateur et le token d'accès existent, retourner l'en-tête d'autorisation
  if (accessToken) {
    console.log("access_token to user ", accessToken);
      return { Authorization: 'Bearer ' + accessToken }; // Pour Spring Boot back-end
      // return { 'x-access-token': user.accessToken }; // Pour Node.js Express back-end
  } else {
      // Si l'utilisateur n'est pas connecté, retourner un objet vide
      return {};
  }
}