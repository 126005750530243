import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/App.css";

import AuthService from "./services/auth.service";
import EventBus from "./common/EventBus";


import Navigation from "./components/navigation";

import Header from "./components/common/Header";
import Banner from "./components/common/Banner";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Welcome from "./components/welcome/welcome";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardFreemium from "./components/board-freemium.component";
import BoardAssure from "./components/board-assure.component";
import ProductList from "./components/maintenance/productlist";
import MaintenanceCard from "./components/maintenance/maintenancecard";
import MaintenanceCardDetail from "./components/maintenance/maintenancecarddetail";
import ViewCalendar from "./components/calendar/viewcalendar";
import SmartFix from "./components/smartfix/smartform";
import ViewNotif from "./components/notification/viewnotif";
import ApplianceList from "./components/portfolio/appliancelist";
import Univers from "./components/portfolio/univers";
import UniverDetail from "./components/portfolio/univerdetail";
import UniverCRUDForm from "./components/common/UniverCRUDForm";

import CarboTool from "./components/tool/CarboTool";
import CarboMap from "./components/tool/CarboMap";
import CallPlanning from "./components/tool/CallPlanning";

import ApplianceDetail from "./components/portfolio/appliancedetail";
import ApplianceUpdateForm from "./components/portfolio/ApplianceUpdateForm";
import ApplianceAdd from "./components/portfolio/applianceadd";


import RepairPage from "./components/repair/RepairPage";

import AddDevicePage from "./components/newdevice/DeviceAddPage";

import AppSearch from "./components/portfolio/appsearch";
import ApplianceReplace from "./components/replaceappliance";
import ProductReplace from "./components/replaceproduct";
import ActionSelect from "./components/secondlife/actionselect";
import Give from "./components/secondlife/give";
import Sell from "./components/secondlife/sell";
import Recycle from "./components/secondlife/recycle";
import OptionSelect from "./components/renew/optionselect";
import CircularTip from "./components/renew/circulartip";
import FundSearch from "./components/renew/fundsearch";
import RenewOffer from "./components/renew/renewoffer";
import AuthVerify from "./common/auth-verify";

const ContainerWrapper = ({ children }) => {
  const location = useLocation();
  const fullPageRoutes = ['/', '/login', '/register', '/welcome'];

  const isFullPage = fullPageRoutes.includes(location.pathname);

  return isFullPage ? children : (
    <div className="container app-container container-fluid">
      {children}
      <div className="mt-4 mb-4"><br /></div>
    </div>
  );
};


function App() {
  const [currentUser, setCurrentUser] = useState(undefined);
  const navigate = useNavigate();

  // Définir l'état pour contrôler l'affichage du Header et du Banner
  const [showBanner, setShowBanner] = useState(false);//par défault on affiche pas banner
  const [showNavBar, setShowNavBar] = useState(true);//par défault on affiche  NavBar
  const [showFreemiumBoard, setShowFreemiumBoard] = useState(false);//par défault on affiche pas banner
  const [showAssureBoard, setShowAssureBoard] = useState(true);//par défault on affiche  NavBar
  
  const [showHeader, setshowHeader] = useState(true);//par défaut on affiche le header

  const location = useLocation();
 
  useEffect(() => {
    if(window.innerWidth>991)  document.documentElement.style.backgroundColor = "var(--color-light-green)";
    else document.documentElement.style.backgroundColor = "white";
    // Modifier l'affichage du Header et du Banner en fonction de la route active
    switch (location.pathname) {
      case '/login':
        setShowNavBar(false);
        setshowHeader(false);
        setShowBanner(false);
        break;
      case '/register':
        setShowNavBar(false);
        setshowHeader(false);
        setShowBanner(false);
        break;
      case '/welcome':
        setShowNavBar(false);
        setshowHeader(false);
        setShowBanner(false);
        break;
      case '/profile':
      case '/calendar':
      case '/notification':
      case '/entretien/fiche':
      case '/entretien/fichedetail':
        setshowHeader(false);
        setShowBanner(false);
        setShowNavBar(true);
        break;
      case '/home':
        setshowHeader(true);
        setShowBanner(true);
        setShowNavBar(true);
        break;
      default:
        setshowHeader(true);
        setShowBanner(false);
        setShowNavBar(true);
        break;
    }


    console.log("get current user")
    const user = AuthService.getCurrentUser();
    if (user) {
      console.log("get current user 1", user)
      setCurrentUser(user);
    }else if (location.pathname ==="/register"){
    }else {
      logOut();
      console.log("get current user 2")
      navigate("/login");
    }
    
    // Capture global errors
    window.onerror = (message, source, lineno, colno, error) => {
      console.log("Global error captured:", message);
      // Affiche un message d'erreur à l'utilisateur
     // alert("Une erreur s'est produite, vous allez être redirigé vers la page d'accueil.");

      // Vérifiez si l'utilisateur est connecté
      if (user) {
       // navigate("/home");  // Redirige vers la page d'accueil
      } else {
        console.log("get current user 3")
        //navigate("/login");  // Redirige vers la page de connexion
      }
      //window.location.reload();  // Rafraîchit la page après navigation
    };


    EventBus.on("logout", logOut);

    // Cleanup event listener on unmount
    return () => {
      EventBus.remove("logout");
    };
  }, [navigate]);

  function logOut() {
    AuthService.logout();
    setShowFreemiumBoard(false);
    setShowAssureBoard(false);
    setCurrentUser(undefined);
    console.log("get current user on logOut")
    navigate("/login");
  }

  return (
  
    <>  
      {showHeader && <Header />}
      {showBanner && <Banner />} 
      {showNavBar && <Navigation/>}
      <ContainerWrapper>
        <Routes>
          <Route path="" element={<Login/>} />
          <Route path="/welcome" element={<Welcome/>} />
          
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home /> }/>

          <Route path="/entretien/fiche" element={<MaintenanceCard />} />
          <Route path="/entretien/fichedetail" element={<MaintenanceCardDetail />} />
          
          <Route path="/productlist" element={<ProductList />} />
          
          <Route path="/calendar" element={<ViewCalendar />} />
          
          <Route path="/notification" element={<ViewNotif />}/>
          
          <Route path="/inventaire/univers" element={<Univers />} />
          <Route path="/inventaire/univers/:id/detail" element={<UniverDetail />} />
          <Route path="/inventaire/univers/update" element={<UniverCRUDForm />} />

          <Route path="/inventaire/univers/appliance/fichedetail" element={<ApplianceDetail />} />
          <Route path="/inventaire/univers/appliance/update" element={<ApplianceUpdateForm />} />

          <Route path="/inventaire" element={<ApplianceList />} />
          <Route path="/inventaire/nouveau_2" element={<ApplianceAdd />} />
          <Route path="/inventaire/search" element={<AppSearch />} />

          <Route path="/new-device" element={<AddDevicePage />} />

          <Route path="/carbotool" element={<CarboTool />} />
          <Route path="/carbomap" element={<CarboMap />} />
          <Route path="/callplan" element={<CallPlanning />} />

          
          <Route path="/replace/appliance" element={<ApplianceReplace />} />
          <Route path="/replace/product" element={<ProductReplace />} />
          
          <Route path="/secondlife/actionselect" element={<ActionSelect />} />
          <Route path="/secondlife/give" element={<Give />} />
          <Route path="/secondlife/sell" element={<Sell />} />
          <Route path="/secondlife/recycle" element={<Recycle />} />
          
          <Route path="/renew/optionselect" element={<OptionSelect />} />
          <Route path="/renew/fundsearch" element={<FundSearch />} />
          <Route path="/renew/tip" element={<CircularTip />} />
          <Route path="/renew/offer" element={<RenewOffer />} />

          <Route path="/repair" element={<RepairPage />} />


          <Route path="/smarfix" element={<SmartFix />} />
          
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />}/>
          <Route path="/profile" element={<Profile />}/>
          <Route path="/user" element={<BoardUser />} />
          
          <Route path="/freemium" element={<BoardFreemium />} />
          <Route path="/assure" element={<BoardAssure />} />
        </Routes>
        </ContainerWrapper>
      <AuthVerify logOut={logOut} />  
    </>
  );
}

export default App;
