import React, { useEffect } from "react";
import { withRouter } from "./with-router";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

  const AuthVerify = (props) => {
    useEffect(() => {
        // Vérifier si l'option "rememberMe" est activée
        const rememberMe = localStorage.getItem("rememberMe") === "true";

        // Récupérer l'utilisateur depuis localStorage ou sessionStorage
        const accessToken = rememberMe
            ? localStorage.getItem("accessToken") // Si "rememberMe" est activé
            : sessionStorage.getItem("accessToken"); // Sinon
        
        if (accessToken) {
            // Décoder le JWT pour vérifier l'expiration
            const decodedJwt = parseJwt(accessToken);

            // Si le token a expiré, déclencher la déconnexion
            if (decodedJwt.exp * 1000 < Date.now()) {
                props.logOut();
            }
        }
    }, [props]);

    return <div></div>;
  };

export default AuthVerify;