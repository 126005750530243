
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Onboarding1,Onboarding2, Onboarding3 } from "../common/Icon";
import "../../css/welcome.css"


const welcomeSteps = {
  welcome: {
    titre: "Tout à Portée de Main",
    description: "Regroupez vos factures, manuels d'utilisation, et fiches d'entretien en un seul endroit pour un accès pratique et immédiat.",
    mediaSrc: null,
    imageComponent: Onboarding1,
  },
  welcome2: {
    titre: "Services Éco-responsables",
    description: "Participez activement à l'économie circulaire avec la revente, le don, ou le recyclage de vos appareils.",
    mediaSrc: null,
    imageComponent: Onboarding2,
  },
  welcome3: {
    titre: "Suivez Mia, Réparez Facilement",
    description: "Mia assistance intelligente propose des guides DIY détaillés ou les accès rapidement aux aides et services de réparation adaptés à vos besoins.",
    mediaSrc:null,
    imageComponent: Onboarding3,
  },
};

const Content = ({ titre, description, mediaSrc, isVideo = false, currentStep, handleStepClick,handleNextStep }) => {
  const navigate = useNavigate();

  console.log("Dans Welcome 3");
  return (
    <div className="content-container">
      <h1>{titre}</h1>
      <p className="content-description">{description}</p>

      {isVideo && (
        <video className="content-media" autoPlay loop muted playsInline controls>
          <source src={mediaSrc} type="video/mp4" />
          <p>Votre navigateur ne supporte pas la lecture de vidéos.</p>
        </video>
      )}

      <div className="button-container">
        <button className="btn-primary" onClick={handleNextStep}>
          <span>Suivant</span>
        </button>

        <div className="footer">
          <div className="welcome-stepper" role="navigation" aria-label="Progression des étapes">
            {[0, 1, 2].map((step) => (
              <button
                key={step}
                className={`welcome-step ${currentStep === step ? "current" : "circle"}`}
                onClick={() => handleStepClick(step)}
                aria-label={`Étape ${step + 1}`}
                aria-current={currentStep === step ? "step" : undefined}
              />
            ))}
          </div>

          <button className="home-button" onClick={() => navigate("/home", { replace: true })}>
            Passer
          </button>
        </div>
      </div>
    </div>
  );
};

const Welcome = () => {
  console.log("Dans Welcome 1");
  const navigate = useNavigate();
  const { step } = useParams();
  const [currentStep, setCurrentStep] = useState(step || "welcome");
  const currentStepKeys = Object.keys(welcomeSteps);
  const currentStepIndex = currentStepKeys.indexOf(currentStep);
  const currentStepData = welcomeSteps[currentStep] || welcomeSteps["welcome"];
  const ImageComponent = currentStepData.imageComponent;

  console.log("Dans Welcome 2");
 
  const handleStepClick = (stepIndex) => {
    setCurrentStep(currentStepKeys[stepIndex]);
  };

  const handleNextStep = () => {
    if (currentStepIndex < currentStepKeys.length - 1) {
      setCurrentStep(currentStepKeys[currentStepIndex + 1]);
    }else if (currentStepIndex >= currentStepKeys.length - 1){
      navigate("/home", { replace: true });
    }
  };

  return (
    <main className="welcome-main" role="main">
      <section className="welcome-container">
        <div className="welcome-left" role="region" aria-labelledby="welcome-heading">
          <Content
            titre={currentStepData.titre}
            description={currentStepData.description}
            mediaSrc={currentStepData.mediaSrc}
            isVideo={currentStepData.mediaSrc ? true  : false}
            currentStep={currentStepIndex}
            handleStepClick={handleStepClick}
            handleNextStep={handleNextStep}
          />
        </div>

        <div className="welcome-right" role="region" aria-hidden="true">
          <ImageComponent style={{
              width: 'auto',
              height: '100vh',
              objectFit: 'cover',
            }}/>
          <div className="content-right">
            <Content
              titre={currentStepData.titre}
              description={currentStepData.description}
              mediaSrc={currentStepData.mediaSrc}
              isVideo={currentStepData.mediaSrc ? true  : false}
              currentStep={currentStepIndex}
              handleStepClick={handleStepClick}
              handleNextStep={handleNextStep}
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default Welcome;