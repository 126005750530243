import React, { useState } from "react";
import CustomSlider from "./CustomSlider";
import DonutChart from "./DonutChart";
import { Ademe, Empreinte,UpArrow,DownArrow, Dcare } from "./Icon";
import ColChart from "./ColChart";

export const carbonData = [
  ['Type;Nom de produit;Matières Premières;Approvisionnement;Mise En Forme;Assemblage;Distribution;cradle to grave par produit;cradle to grave par an;SuperBOM kg par produit;durée de vie;fin de vie'], 
  ['Appareil à raclettes;Appareil à raclettes 6-8p;10;0.395;0.641;4.02;1.71;20.57;1.87;;11;0'], 
  ['Aspirateur;Aspirateur ménager sans sac;22.9;2.78;8.07;8.83;9.83;69;6.9;7.2;10;0'], 
  ['Chauffe-eau;Ballon électrique chauffe-eau 200L;112;5.54;6.79;9.6;25.7;4161.09;244.77;;17;0'], 
  ['Bouilloire;Bouilloire;3.93;0.237;0.59;4.02;1.13;40.74;6.79;;6;0'], 
  ['Climatiseur;Climatiseur mobile;170;5.2;18.7;8;37;414;69;;6;65.42'], 
  ['Congélateur;Congélateur coffre 261l;167;13;44.2;19.6;57.6;492;33;52;15;0'], 
  ['Four;Four électrique encastrable;133;8.19;33.6;12.9;29.9;319;17;34;19;0'], 
  ['Hotte;Hotte décorative à extraction;42;1.76;3.01;8.36;5.3;115.6;11.56;;10;0'], 
  ['Lave-linge;Lave-linge capacité 7kg;191;12.2;47.6;24;66.3;539;49;79;11;0'], 
  ['Lave-vaisselle;Lave-vaisselle standard 12 couverts;155;9.09;40.1;15.7;51.3;513;47;53;11;0'], 
  ['Machine à café;Machine à café dosette;8.46;0.394;1.28;9.26;3.06;250;50;;5;52.75'], 
  ['Machine à pain;Machine à pain;31.4;1.33;5.26;8;7.11;96;10;6.4;9;0'], 
  ['Micro-onde;Micro-onde;58.6;3.43;6.91;10.4;19;118;15;13;8;0'], 
  ['Mini-four;Mini-four électrique;50.5;3.12;12.8;4.88;11.4;190;10;13;19;0'], 
  ['Plaques de cuisson;Plaques de cuisson vitrocéramiques 9000W;45.2;1.63;0.754;8.13;9.6;275.6;27.56;;10;0'], 
  ['Radiateur;Radiateur électrique 1000 W à inertie;68.2;2.66;1.4;8.74;13.2;1616;101;;16;0'], 
  ['Robot multifonctions;Robot multifonction;22.5;1.16;4.63;8;5.02;43;4.3;4.4;10;0'], 
  ['Réfrigérateur;Réfrigérateur combiné;133;13.5;28;22;60.8;382;35;56;11;0'], 
  ['Séché-linge;Sèche-linge à condensation Capacité 6kg;190;10.6;43.8;15.9;41.6;588;45;43;13;0'], 
  ['Yaourtière;yaourtière 8 pots;10.4;0.694;1.41;6.15;3.05;23;2.3;2.5;10;0']
];

export const calculateTotalCarbonImpact = (backendResponse) => {
  if (!backendResponse || !backendResponse.data) {
    throw new Error('Réponse invalide du backend. Impossible de calculer l’impact carbone.');
  }

  const header = carbonData[0][0].split(';'); // Extraire les en-têtes depuis la première ligne
  const cradleToGraveIndex = header.indexOf('cradle to grave par produit');

  if (cradleToGraveIndex === -1) {
    throw new Error('Impossible de trouver "cradle to grave par produit" dans carbonData.');
  }

  let totalImpact = 0;

  for (const [productName, count] of Object.entries(backendResponse.data)) {
    // Trouver la ligne correspondante dans carbonData
    const matchingRow = carbonData.find(row => row[0].toLowerCase().includes(productName.toLowerCase()));
    if (matchingRow) {
      const rowData = matchingRow[0].split(';');
      const cradleToGraveValue = parseFloat(rowData[cradleToGraveIndex]) || 0;

      // Calcul de l’impact carbone par type de produit
      totalImpact += cradleToGraveValue * count;
    } else {
      console.warn(`Produit non trouvé dans carbonData : ${productName}`);
      // Calcul de l’impact carbone par type de produit
      totalImpact += 1 * count;
    }
  }

  return totalImpact;
};

export const Simulateur = () => {
  // Préparer les données en séparant les colonnes
  const headers = carbonData[0][0].split(";"); // Extraire les en-têtes
  const rows = carbonData.slice(1).map((row) => row[0].split(";")); // Séparer les colonnes des données

  const [selectedType, setSelectedType] = useState(""); // Type sélectionné
  const [years, setYears] = useState(1); // Nombre d'années sélectionnées
  const [selectedProduct, setSelectedProduct] = useState(null); // Produit sélectionné
  const [globalUsage, setGlobalUsage] = useState(0); // Usage
  const [globalImpact, setGlobalImpact] = useState(0); // Cradle to grave
  const [globalProduction, setGlobalProduction] = useState(0); // annual usage
  const [lifetime, setLifetime] = useState(0); // Lifetime
  const [endOfLife, setEndOfLife] = useState(0); // recycle end of life
  const [labelLongProduct, setLabelLongProduct] = useState(0); // product name
  const [annualUsage, setAnnualUsage] = useState(0); // annual usage
  const [simulatedGlobalImpact, setSimulatedGlobalImpact] = useState(0); // recycle end of life
  const [simulatedUsage, setSimulatedUsage] = useState(0); // recycle end of life
 
  const [dataChart, setDataChart] = useState([]); // annual usage
  const [labelChart, setLabelChart] = useState([]); // annual usage
  
  const [showDetailsProd, setShowDetailsProd] = useState(false);

  const toggleDetailsProd = () => {
    setShowDetailsProd(!showDetailsProd);
    const label=['Matières Premières','Approvisionnement','Mise En Forme','Assemblage','Distribution'];
    setLabelChart(label);
    const matiere=selectedProduct ? parseFloat(selectedProduct[2]) || 0 : 0;
    const appro=selectedProduct ? parseFloat(selectedProduct[3]) || 0 : 0;
    const misenforme=selectedProduct ? parseFloat(selectedProduct[4]) || 0 : 0;
    const assemblage=selectedProduct ? parseFloat(selectedProduct[5]) || 0 : 0;
    const distribution=selectedProduct ? parseFloat(selectedProduct[6]) || 0 : 0;
    const dataToChart=[matiere,appro,misenforme,assemblage,distribution];
    setDataChart(dataToChart);

  };


  // Fonction pour gérer la sélection d'un produit
  const productSelected = (e) => {
    const selectedValue = e.target.value;
    setSelectedType(selectedValue);

    // Trouver le produit sélectionné
    const selectedProduct = rows.find((row) => row[0] === selectedValue);
    setSelectedProduct(selectedProduct);
    
    // Calculs basés sur le produit sélectionné
    const globalImpact= selectedProduct ? parseFloat(selectedProduct[7]) || 0 : 0;
    setGlobalImpact(globalImpact);
    const lifetime=selectedProduct ? parseFloat(selectedProduct[10]) || 1 : 1;
    setLifetime(lifetime);
    setYears(lifetime);
    const endOfLife=selectedProduct ? parseFloat(selectedProduct[11]) || 0 : 0;
    setEndOfLife(endOfLife);
    setLabelLongProduct(selectedProduct ? selectedProduct[1]:"");

    const globalProduction = selectedProduct
      ? [
          parseFloat(selectedProduct[2]) || 0,
          parseFloat(selectedProduct[3]) || 0,
          parseFloat(selectedProduct[4]) || 0,
          parseFloat(selectedProduct[5]) || 0,
          parseFloat(selectedProduct[6]) || 0,
        ].reduce((sum, value) => sum + value, 0)
      : 0;
    setGlobalProduction(globalProduction)

    const globalUsage = globalImpact - globalProduction;
    setGlobalUsage(globalUsage); 
    //console.log('calculé globalUsage = ', globalUsage)
    //console.log('calculé lifetime = ', lifetime)
    const annualUsage=((globalUsage-endOfLife)/lifetime).toFixed(2);
    //console.log('calculé annualUsage = ', annualUsage)
    setAnnualUsage(annualUsage);
  
    const simulatedUsage = globalUsage;
    setSimulatedUsage(simulatedUsage); 
    //console.log('calculé simulatedUsage = ', simulatedUsage)
    // Calcul de l'empreinte simulée
    setSimulatedGlobalImpact(globalImpact);

  };


   // Fonction pour gérer la sélection du nombre d'année
   const onYearChange = (value) => {
    setYears(value);
    console.log('calculé year', value)
    const usage = (value * annualUsage)+ endOfLife;
    console.log('calculé usage simulé = ', usage)
    setSimulatedUsage(usage); 
    const simulatedGlobalImpact = usage+ globalProduction;
    setSimulatedGlobalImpact(simulatedGlobalImpact);

  };
 
  const labels = ['Production', 'Usage'];
  const data = [globalProduction, simulatedUsage];
  const backgroundColors = ['#4FB573', '#E2F3E8'];

  return (
    <div>
    <div className="mt-3 boxShadow" >
    <div style={{ display: "flex", alignItems: "center" }}>
     
      
      {/* Colonne droite pour le texte */}
      <div style={{ flex: "0 0 60%",alignItems: "center", padding : "10px" }}>
        <p className="text-medium" style={{ textAlign: "right", margin: 0 }}>
          Estimation d'émission de Carbone des électroménagers
          <br />
          <em className="text-small">Indicateur "Changement climatique"</em>
        </p>
      </div>

       {/* Colonne gauche pour les logos */}
       <div style={{ flex: "0 0 20%", display: "flex", justifyContent: "left", alignItems: "center", gap: "10px" }}>
        par <Dcare style={{width: '50%', height: 'auto'}}/>
      </div>
    </div>
    

      {/* Liste déroulante pour sélectionner le type de produit */}
      <div className="mt-4" style={{ marginBottom: "20px"}}>
        <label htmlFor="productSelect">
          <p className="text-medium">Choisis ton produit :</p>
        </label>
        <select
          id="productSelect"
          value={selectedType}
          onChange={productSelected}
          style={{padding: "5px",width: "100%"}}
          aria-label="Sélectionnez le type de produit pour afficher son empreinte carbone"
          className="text-small"
        >
          <option value="">-- Choisir un produit --</option>
          {rows.map((row, index) => (
            <option key={index} value={row[0]}>
              {row[0]}
            </option>
          ))}
        </select>
      </div>

      {/* Afficher les détails du produit sélectionné */}
      {selectedProduct && (
        <div className="text-small mb-3" style={{ width: "90%", margin: "0 auto"}}>
          <p className="text-medium text-center">{labelLongProduct} : <span style={{ whiteSpace: "nowrap" }}>{globalImpact.toFixed(0  )} kg CO₂ émis</span></p>
          <DonutChart labels={labels} data={data} backgroundColors={backgroundColors} />
          <div style={{ display: 'flex',justifyContent: "center", marginBottom:"40px"}}>
          <div style={{ 
              flex: 1, 
              textAlign: 'center', 
              borderRight: '1px solid #ccc', 
              position: 'relative', // Nécessaire pour positionner DownArrow
              cursor: "pointer",
              }} 
              onClick={toggleDetailsProd}
            >
              <p style={{ margin: "0" }} >
                <strong>Production</strong>
                <br />
                {((globalProduction / simulatedGlobalImpact) * 100).toFixed(2)}%
              </p>
              <DownArrow style={{ 
                position: 'absolute', 
                right: '10px', // Distance depuis le bord droit
                top: '40%', // Centré verticalement dans le conteneur
                transform: 'translateY(-50%)' 
              }} />
            </div>

            <div style={{ flex: 1, textAlign: 'center' }}>
              <p style={{ margin: "0"}}>
                <strong>Usage</strong><br />
                {((simulatedUsage / simulatedGlobalImpact) * 100).toFixed(2)}%
              </p>
            </div>
          </div>

          {showDetailsProd && (
              <div style={{ marginBottom: "50px", position: "relative" }}>
              {/* Ligne supérieure avec le bouton */}
                <div 
                onClick={() => setShowDetailsProd(false)} // Fonction pour masquer showDetailsProd
                style={{
                  background: "none",
                  fontSize: "0.5rem",
                  borderBottom: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "5px 7px",
                  cursor: "pointer",
                  display: "flex", justifyContent: "space-between", marginBottom: "10px"
                }}
                >
                  <span className="text-medium">Emissions en kg CO₂ de Production</span>
                  <UpArrow/>
                </div>
                <ColChart labels={labelChart} data={dataChart} backgroundColors={backgroundColors}  />
              </div>
          )}

          <div
          style={{
            display: "flex",
            justifyContent: "center", // Centre horizontalement
          }}>
            <CustomSlider
              min={1}
              max={20}
              defaultValue={years}
              onChange={onYearChange}
            />
          </div>
        </div>
      )}
    </div>
    <div className="mt-3 text-small" style={{ display: 'flex', alignItems: 'center', gap: '10px',  }}>
      <span>Sources : </span>
      <Ademe style={{ width: '30px', height: 'auto' }} />
      <Empreinte style={{ width: '100px', height: 'auto' }} />
      <a style={{color: 'black'}} href="https://base-empreinte.ademe.fr/donnees/jeu-donnees">Base Empreinte ADEME</a>
    </div>
    </div>
  );
};

export default Simulateur;

