import React, { useEffect, useState } from 'react';


export const EditIcon = ({ onClick }) => (
  <span
    onClick={onClick}
    style={{
      position: 'absolute',
      right: '5px',
      top: '50%',
      transform: 'translateY(-50%) scaleX(-1)', // Flip vertical
      cursor: 'pointer',
      fontSize: '1rem'
    }}
  >
    ✎
  </span>
);

export const FileIcon = ({ style }) => (
  <span style={{fontSize: '1rem', height: 'auto', ...style  }}>📄</span>
);

export const PlanetIcon = ({ style }) => (
  <span style={{fontSize: '7rem', height: 'auto', ...style  }}>🌍</span>
);

export const ArmIcon = ({ style }) => (
  <span style={{fontSize: '1rem', height: 'auto', ...style  }}>💪</span>
);

export const UpArrow = ({ style }) => (
  <span style={{fontSize: '1rem', height: 'auto', ...style  }}>▲</span>
);

export const DownArrow = ({ style }) => (
  <span style={{ fontSize: '1rem', height: 'auto', ...style }}>▼</span>
);

export const Dcare = ({ style }) => (
  <img 
           src={`${process.env.PUBLIC_URL}/icons/Logo_dcare.svg`} 
           alt="dcare" 
           style={{...style }} 
       />
 );

export const Ademe = ({ style }) => (
 <img 
          src={`${process.env.PUBLIC_URL}/icons/ademe.svg`} 
          alt="ademe" 
          style={{ width: '100%', height: 'auto', ...style }} 
      />
);

export const Onboarding1 = ({ style }) => (
  <img 
           src={`${process.env.PUBLIC_URL}/icons/Onboarding1.svg`} 
           alt="Onboarding image 1" 
           style={{
            display: 'block',     // Évite des marges indésirables
            ...style              // Étend les styles passés au composant
          }} 
       />
 );

 export const Onboarding2 = ({ style }) => (
  <img 
           src={`${process.env.PUBLIC_URL}/icons/Onboarding2.svg`} 
           alt="Onboarding image 2" 
           style={{
            display: 'block',     // Évite des marges indésirables
            ...style              // Étend les styles passés au composant
          }} 
       />
 );
 
 export const Onboarding3 = ({ style }) => (
  <img 
           src={`${process.env.PUBLIC_URL}/icons/Onboarding3.svg`} 
           alt="Onboarding image 3" 
           style={{
            display: 'block',     // Évite des marges indésirables
            ...style              // Étend les styles passés au composant
          }} 
       />
 );

 export const Badge = ({ style,src }) => (
  <img 
           src={`${process.env.PUBLIC_URL}/icons/badge/${src}`} 
           alt="Badge earned by user" 
           style={{
            width: '20px',
            display: 'block',     // Évite des marges indésirables
            ...style              // Étend les styles passés au composant
          }} 
       />
 );


 export const EyeOpen = ({ style }) => (
  <img 
           src={`${process.env.PUBLIC_URL}/icons/Eye-solid.svg`} 
           alt="Eye icon show password" 
           style={{
            width: '20px',
            display: 'block',     // Évite des marges indésirables
            ...style              // Étend les styles passés au composant
          }} 
       />
 );

 export const EyeSlash = ({ style }) => (
  <img 
           src={`${process.env.PUBLIC_URL}/icons/Eye-slash-solid.svg`} 
           alt="Eye icon hide password" 
           style={{
            width: '20px',
            display: 'block',     // Évite des marges indésirables
            ...style              // Étend les styles passés au composant
          }} 
       />
 );


export const Spareka = ({ style }) => (
  <img 
           src={`${process.env.PUBLIC_URL}/icons/image/spareka.jpg`} 
           alt="sparke" 
           style={{ width: '200px', height: 'auto', ...style }} 
       />
 );

 export const Carbometer = ({ style }) => (
  <img 
           src={`${process.env.PUBLIC_URL}/icons/Carbometer.svg`} 
           alt="sparke" 
           style={{ width: '100%', height: 'auto', ...style }} 
       />
 );

 export const Circularmap = ({ style }) => (
  <img 
           src={`${process.env.PUBLIC_URL}/icons/Circularmap.svg`} 
           alt="sparke" 
           style={{ width: '100%', height: 'auto', ...style }} 
       />
 );

export const Mia = ({ style }) => (
  <img 
           src={`${process.env.PUBLIC_URL}/icons/image/MiaBig.png`} 
           alt="Mia" 
           style={{ width: '100%', height: 'auto', filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))', ...style }} 
       />
 );

 export const MiaBig = ({ style }) => (
  <img 
           src={`${process.env.PUBLIC_URL}/icons/image/MiaBig.png`} 
           alt="Mia" 
           style={{ width: '200px', height: 'auto', filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))', ...style }} 
       />
 );

export const Empreinte = ({ style }) => (
  <img 
           src={`${process.env.PUBLIC_URL}/icons/base-empreinte.svg`} 
           alt="empreinte" 
           style={{ width: '100%', ...style  }} 
       />
 );

function Icon({ src, alt, className, onClick, style }) {
  const [svgContent, setSvgContent] = useState(null);
  const iconSrc = `${process.env.PUBLIC_URL}/icons/${src}`;

  useEffect(() => {
    if (src.endsWith('.svg')) {
        //console.log("Fetching icon from:", iconSrc);
        fetch(iconSrc)
            .then(response => {
                //console.log("Response status:", response.status);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.text();
            })
            .then(data => {
                //console.log("SVG Content:", data);
                setSvgContent(data);
            })
            .catch(error => console.error("Error fetching the SVG:", error));
    }
}, [iconSrc, src]);


  if (svgContent) {
      return (
          <div 
              className={className} 
              onClick={onClick} 
              style={style} 
              aria-label={alt}
              dangerouslySetInnerHTML={{ __html: svgContent }}
          />
      );
  }

  return (
      <img 
          src={iconSrc} 
          alt={alt} 
          className={className} 
          onClick={onClick} 
          style={style} 
      />
  );
}

  function IconByType(type) {
    // Logique pour déterminer quelle icône renvoyer en fonction du type
    // Par exemple :
    const iconMap = {
      entretien: 'spray-can-sparkles.svg',
      reparation: 'wrench.svg',
      secondvie: 'hand-holding-heart.svg',
      default : 'book-open.svg',
    };
    switch (type) {
      case 'Entretien':
        return <img src={process.env.PUBLIC_URL +'/icons/'+iconMap.entretien} alt="Entretien" style={{ width: '30px' }} />;
      case 'Réparation':
        return <img src={process.env.PUBLIC_URL +'/icons/'+iconMap.reparation} alt="Reparation" style={{ width: '30px' }} />;
      case 'Secondevie':
        return <img src={process.env.PUBLIC_URL +'/icons/'+iconMap.reparation} alt="Seconde Vie" style={{ width: '30px' }} />;
      default:
        return <img src={process.env.PUBLIC_URL +'/icons/'+iconMap.default} alt="Default" style={{ width: '30px' }} />;
    }
  }

export { IconByType };
export default Icon;