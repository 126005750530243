import api from './api'; // Importez l'instance Axios configurée


import authHeader from './auth-header';
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';
import UploadService from './upload.service'; // Importez votre fonction getUniver si elle est dans un autre fichier
import AuthService from './auth.service';

const API_URL = `${config.serverIP}/${config.apiVersion}`;

class UniverService{

  async getUniver() {

    const userId = AuthService.getUserId();
    console.log("getUniver ", userId);
    // Vérifier si l'utilisateur est défini avant de continuer
    if (!userId) {
        return Promise.reject("User ID not found");
    }

    // Construire l'URL avec l'ID utilisateur
    const url = `${API_URL}/profils/users/${userId}/foyers/univers`;

    // Récupérer les headers d'authentification
    const headers = authHeader();

    // Effectuer la requête GET avec Axios
    const res = await api.get(url, { headers })
        .then(response => {
            // Gérer la réponse ici si besoin
            
            return response.data;
        })
        .catch(error => {
            // Gérer l'erreur ici
            console.error("Error fetching univers:", error);
            throw error;
        });
    return res;
  }

  getOldUniver() {
    
    const userId = AuthService.getUserId();
    // Vérifier si l'utilisateur est défini avant de continuer
    if (!userId) {
        console.error("User ID not found in localStorage");
        return Promise.reject("User ID not found");
    }

    // Construire l'URL avec l'ID utilisateur
    const url = `${API_URL}/profils/users/${userId}/foyers/customunivers`;

    // Récupérer les headers d'authentification
    const headers = authHeader();

    // Effectuer la requête GET avec Axios
    return api.get(url, { headers })
        .then(response => {
            // Gérer la réponse ici si besoin
            
            return response.data;
        })
        .catch(error => {
            // Gérer l'erreur ici
            console.error("Error fetching univers:", error);
            throw error;
        });
  }

  creatUniver = async (refUniverId, file, name,description) => {

    let fileToUpload = UploadService.checkImageSize(file);

    console.log("filetoupload after resize ", fileToUpload);
    
    const userId = AuthService.getUserId();
     // Vérifier si l'utilisateur est défini avant de continuer
    if (!userId) {
        console.error("User ID not found in localStorage");
        return Promise.reject("User ID not found");
    }

    // Construire l'URL avec l'ID utilisateur
    const url = `${API_URL}/profils/users/${userId}/foyers/univers`;


    const formData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('name', name); // Ajoutez le champ 'name'
    formData.append('refUniverId', refUniverId); // Ajoutez le champ 'name'
    formData.append('description', description); // Ajoutez le champ 'name'
  
    // Récupérer les headers d'authentification
    const headers = authHeader();


    try {
      const response = await api.post(url, formData, { headers });
      console.log('Univer créé avec succès:', response.data);
      return response.data;
    } catch (error) {
      console.error('Erreur lors de la création de l\'univer:', error);
    }
  };

   
  updateUniver = async (univerId, file, name, description) => {

    // Si un fichier a été modifié, vérifier sa taille et le redimensionner si nécessaire
    let fileToUpload = await UploadService.checkImageSize(file);
    
    const userId = AuthService.getUserId();
    // Vérifier si l'utilisateur est défini avant de continuer
    if (!userId) {
      console.error("User ID not found in localStorage");
      return Promise.reject("User ID not found");
    }
  
    // Construire l'URL avec l'ID utilisateur
    const url = `${API_URL}/profils/users/${userId}/foyers/univers/${univerId}`;
  
    const formData = new FormData();
  
    // Ajouter uniquement les champs modifiés
    if (fileToUpload) {
      formData.append('file', fileToUpload);
    }
  
    if (name) {
      formData.append('name', name);
    }
  
    if (description) {
      formData.append('description', description);
    }
  
    // Récupérer les headers d'authentification
    const headers = authHeader();
  
    try {
      const response = await api.put(url, formData, { headers });
      console.log('Univer mis à jour avec succès:', response.data);
      return response.data;
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'univer:', error);
    }
  };
  


    
}


// Créez une instance de NotifService
const UniverServiceInstance = new UniverService();

// Exportez l'instance en tant qu'export par défaut
export default UniverServiceInstance;


//export default new EventService();