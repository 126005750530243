import axios from "axios";
import config from './config';

const API_URL = `${config.serverIP}/api/auth/`;

class AuthService {

    async login(username, password, rememberMe) {
        console.log("connexionnnnnnnnnnnnnn 1");
      const url = API_URL + "signin";
      
      const response = await axios.post(url, {
          username,
          password
      });
      console.log("connexionnnnnnnnnnnnnn 2");
      if (response.data.accessToken) {
          // Stocker les tokens en fonction de l'option "rememberMe"
          if (rememberMe) {
              localStorage.setItem("user", JSON.stringify(response.data));
              localStorage.setItem("accessToken", response.data.accessToken);
              localStorage.setItem("refreshToken", response.data.refreshToken);
              localStorage.setItem("rememberMe", "true");
          } else {
              sessionStorage.setItem("user", JSON.stringify(response.data));
              sessionStorage.setItem("accessToken", response.data.accessToken);
              sessionStorage.setItem("refreshToken", response.data.refreshToken);
          }
      }
      return response.data;
    }

    logout() {
        // Supprimer les données de localStorage
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("rememberMe");

        // Supprimer les données de sessionStorage
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("refreshToken");
    }

    async register(username, firstname, lastname, email, adresse, telephone, password) {
        const req = {
            "username": username,
            "firstname": firstname,
            "lastname": lastname,
            "email": email,
            "password": password,
            "adresse": adresse,
            "telephone": telephone,
            "role": ["freemium"]
        };
        console.log('register sent ', req);

        return axios.post(API_URL + "signup", req);
    }

    async updateUser(userId, firstname, lastname, email, adresse, telephone, password) {
        const req = {
            "firstname": firstname,
            "lastname": lastname,
            "email": email,
            "adresse": adresse,
            "telephone": telephone,
            "password": password
        };

        console.log('updateUser sent:', req);

        // Effectuer une requête PUT vers le backend avec les nouvelles informations utilisateur
        const updatedUserFromBackend = await axios.put(API_URL + `user/${userId}`, req).then(response => {
            return response.data;
        });

        console.log('user updated from backend ', updatedUserFromBackend);

        // Récupère l'utilisateur du localStorage ou sessionStorage
        const rememberMe = localStorage.getItem("rememberMe") === "true";
        const storedUser = JSON.parse(rememberMe ? localStorage.getItem("user") : sessionStorage.getItem("user"));

        if (storedUser) {
            // Conserve le token et autres informations non mises à jour
            const updatedUser = {
                ...storedUser, // Conserve les propriétés existantes comme token
                firstname: updatedUserFromBackend.firstname,
                lastname: updatedUserFromBackend.lastname,
                email: updatedUserFromBackend.email,
                adresse: updatedUserFromBackend.adresse,
                telephone: updatedUserFromBackend.telephone,
                password: updatedUserFromBackend.password,
            };

            // Met à jour le localStorage ou sessionStorage avec l'utilisateur mis à jour
            if (rememberMe) {
                localStorage.setItem("user", JSON.stringify(updatedUser));
            } else {
                sessionStorage.setItem("user", JSON.stringify(updatedUser));
            }
        }
    }

    getCurrentUser() {
      const rememberMe = localStorage.getItem("rememberMe") === "true";
      const user = rememberMe
          ? JSON.parse(localStorage.getItem("user"))
          : JSON.parse(sessionStorage.getItem("user"));

      return user || null;
    }

    getUserId() {
      // Récupérer l'utilisateur depuis localStorage ou sessionStorage
      const user =  this.getCurrentUser();
     // Retourner l'ID de l'utilisateur ou null si l'utilisateur n'est pas connecté
      return user?.id || null;
    }

    async refreshToken() {
      const rememberMe = localStorage.getItem("rememberMe") === "true";
      const refreshToken = rememberMe
          ? localStorage.getItem("refreshToken")
          : sessionStorage.getItem("refreshToken");

      if (!refreshToken) {
            console.log("Refresh token indisponible")
          throw new Error("Aucun refresh token disponible.");
      }

      const response = await axios.post(API_URL + "refreshtoken", {
          refreshToken,
      });

      if (response.data.accessToken) {
          // Mettre à jour les tokens dans le bon stockage
          if (rememberMe) {
              localStorage.setItem("accessToken", response.data.accessToken);
              localStorage.setItem("refreshToken", response.data.refreshToken);
          } else {
              sessionStorage.setItem("accessToken", response.data.accessToken);
              sessionStorage.setItem("refreshToken", response.data.refreshToken);
          }
          return response.data.accessToken;
      } else {
          throw new Error("Échec du rafraîchissement du token.");
      }
  }

    checkFreemium() {
        const user = this.getCurrentUser();
        if (user) return user.roles.includes("ROLE_FREEMIUM");
        return false;
    }

    getLoginCount(){
        const user = this.getCurrentUser();
        return user?.loginCount || null;
    }


    checkAssure() {
        const user = this.getCurrentUser();
        if (user) return user.roles.includes("ROLE_ASSURE");
        return false;
    }
}

// Créez une instance de AuthService
const AuthServiceInstance = new AuthService();

// Exportez l'instance en tant qu'export par défaut
export default AuthServiceInstance;